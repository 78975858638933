@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #e8e8e8;
}

#root {
  display: grid;
  grid-template-rows: 70px 1fr auto;
  height: 100vh;
}

select {
  background-color: transparent;
  color: white; /* Pour assurer que le texte reste blanc */
  /* Autres styles selon vos besoins */
}

.grid-container {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* grid-template-rows: repeat(auto-fit, minmax(350px, 300px)); */
  /* grid-auto-rows: minmax(350px, 1fr); */
  gap: 20px;
  margin-top: 30px;
}

.expert-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
  height: auto;
}
.expert-container .col-1 {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
}

.expert-container .col-3 {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1664575601711-67110e027b9b?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')
      center/cover;
  transition: background 2s ease-in-out;
}

.expert-container .col-3:hover {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575601711-67110e027b9b?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')
      center/cover;
}

.expert-container .col-1:hover {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.expert-container .col-2 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1516321497487-e288fb19713f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .col-2:hover {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1516321497487-e288fb19713f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}
.expert-container .col-4 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575197593-2e7085ef086b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .col-4:hover {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1664575197593-2e7085ef086b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}
.expert-container .col-5 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1556761175-b413da4baf72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .col-5:hover {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1556761175-b413da4baf72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .col-0 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .col-0:hover {
  background: linear-gradient(rgba(0, 71, 202, 0.82), rgba(1, 8, 109, 0.935)),
    url('https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

@media (min-width: 800px) {
  .expert-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .expert-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .expert-container .col-1 {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .expert-container .col-3 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

/*  */
.grid-rows-auto {
  display: grid;
  grid-auto-rows: auto;
  gap: 30px;
}

.Toastify__toast-container {
  right: 40px;
}
.Toastify__toast {
  width: 390px;
  right: 10px;
}

.Toastify__toast-container--top-right {
  right: 5% !important;
}

input:disabled {
  /* color: rgba(80, 80, 80, 0.7); */
  opacity: 0.5;
}

/* Qui sommes nous */

.expert-container .my-col-1 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
}

.expert-container .my-col-3 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575601711-67110e027b9b?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')
      center/cover;
  transition: background 2s ease-in-out;
}

.expert-container .my-col-0 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .my-col-5 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1556761175-b413da4baf72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .my-col-2 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1516321497487-e288fb19713f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

.expert-container .my-col-4 {
  background: linear-gradient(rgba(40, 40, 40, 0.85), rgba(22, 22, 27, 0.854)),
    url('https://images.unsplash.com/photo-1664575197593-2e7085ef086b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: contain;
}

@media (min-width: 1200px) {
  .expert-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .expert-container .my-col-1 {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .expert-container .my-col-3 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.teams {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.annonce {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.dashboad {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

/* .ordercard {
  grid-template-columns: repeat(5, 200px);
}
@media (min-width: 768px) {
  .ordercard {
    grid-template-columns: repeat(6, 200px);
  }
} */

.orders {
  grid-template-columns: repeat(7, 150px);
}

@media (min-width: 768px) {
  .ordercard {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .orders {
    grid-template-columns: repeat(7, 1fr);
  }
}

/* mobile menu */

.show-menu {
  transform: translateX(0%);
  transition: transform 1s ease-in-out;
}
.hide-menu {
  transform: translateX(-100%);
  transition: transform 1s ease-in-out;
}
